import React from 'react';
import ConfigurationService from '../api-services/ConfigurationService';

function Appointment() {
  const service = ConfigurationService();

  return (
    <div className="container">
      {service.status === 'loading' && <div>Loading...</div>}
      {service.status === 'loaded' &&
        <div>
          <div className="row">
            <h3 id="appointment">Book an Appointment</h3>
          </div>
          <div className="row">
            <p>
            Registered clients can book an appointment with us by phoning <a href={"tel:" + service.payload.phone}>{service.payload.phone}</a> or sending an email to <a href={"mailto:" + service.payload.email}>{service.payload.email}</a>
            </p>
          </div>
        </div>
      }
      {service.status === 'error' && (<div>Error, the backend moved to the dark side.</div>)}
    </div>
  );
}

export default Appointment;
