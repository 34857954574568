import React from 'react';
import logo from '../img/logo.png';

function Environmental() {
  return (
    <div className="container">
      <div className="row p-0 rounded-3 border shadow-lg">
        <div className="col-md-9 col-xl-10 min-vh-100 rounded-end">
          <div className="row rounded-end">
            <div className="text-centre">
              <img src={logo} alt="Weeley Vet Centre" className="align-centre img-fluid" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 p-5">
              <div className="row">
                <h3>WVC Environmental Policy</h3>
                <br />
                <p>
                    We are committed to providing a quality service in a manner that ensures a safe and healthy workplace for our employees and minimises our potential impact on the environment. We will operate in compliance with all relevant environmental legislation and we will strive to use pollution prevention and environmental best practices in all we do.
                
                    We will
                </p>
                <ul>
                    <li>integrate the consideration of environmental concerns and impacts into all of our decision making and activities,</li>
                    <li>promote environmental awareness among our employees and encourage them to work in an environmentally responsible manner,</li>
                    <li>train, educate and inform our employees about environmental issues that may affect their work,</li>
                    <li>reduce waste through re-use and recycling and by purchasing recycled, recyclable or re-furbished products and materials where these alternatives are available, economical and suitable,</li>
                    <li>promote efficient use of materials and resources throughout our facility including water, electricity, raw materials and other resources, particularly those that are non-renewable,</li>
                    <li>avoid unnecessary use of hazardous materials and products, seek substitutions when feasible, and take all reasonable steps to protect human health and the environment when such materials must be used, stored and disposed of,</li>
                    <li>purchase and use environmentally responsible products accordingly,</li>
                    <li>where required by legislation or where significant health, safety or environmental hazards exist, develop and maintain appropriate emergency and spill response programmes,</li>
                    <li>communicate our environmental commitment to clients, customers and the public and encourage them to support it,</li>
                    <li>strive to continually improve our environmental performance and minimise the social impact and damage of activities by periodically reviewing our environmental policy in light of our current and planned future activities.</li>
                    <li>We have a Green Team and a Sustainability Champion – Aiyesha Barker</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Environmental;
