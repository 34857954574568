import React from 'react';
import logo from '../img/logo.png';

function Privacy() {
  return (
    <div className="container">
      <div className="row p-0 rounded-3 border shadow-lg">
        <div className="col-md-9 col-xl-10 min-vh-100 rounded-end">
          <div className="row rounded-end">
            <div className="text-centre">
              <img src={logo} alt="Weeley Vet Centre" className="align-centre img-fluid" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 p-5">
              <div className="row">
                <h3>Privacy Policy</h3>
                <br />
                <h4>Introduction</h4>
                <p>
                  Weeley Veterinary Centre respects your privacy and is committed to protecting your personal data. This privacy notice will inform you as to how we look after your personal data and tell you about your privacy rights and how the law protects you. Please use the Glossary to understand the meaning of some of the terms used in this privacy notice. This privacy notice covers the following content: <br />
                  <br />
                  <ol className="list-group list-group-numbered">
                    <li className="list-group-item"><a href="#1">IMPORTANT INFORMATION AND WHO WE ARE</a></li>
                    <li className="list-group-item"><a href="#2">THE DATA WE COLLECT ABOUT YOU</a></li>
                    <li className="list-group-item"><a href="#3">HOW IS YOUR PERSONAL DATA COLLECTED</a></li>
                    <li className="list-group-item"><a href="#4">HOW WE USE YOUR PERSONAL DATA</a></li>
                    <li className="list-group-item"><a href="#5">DISCLOSURES OF YOUR PERSONAL DATA</a></li>
                    <li className="list-group-item"><a href="#6">INTERNATIONAL TRANSFERS</a></li>
                    <li className="list-group-item"><a href="#7">DATA SECURITY</a></li>
                    <li className="list-group-item"><a href="#8">DATA RETENTION</a></li>
                    <li className="list-group-item"><a href="#9">YOUR LEGAL RIGHTS</a></li>
                    <li className="list-group-item"><a href="#10">GLOSSARY</a></li>
                  </ol>
                </p>
                <h3 id="1">1. Important information and who we are</h3>
                <br />
                <h4>Purpose of this privacy notice</h4>
                <p>
                  This privacy notice aims to give you information on how we collect and process your personal data through your registration with any of practices and use of our website, including any data you may provide through our website when you sign up to our newsletter, or purchase a product or service. 
                  Our website is not intended for children and we do not knowingly collect data relating to children.
                  It is important that you read this privacy notice together with any other privacy notice or fair processing notice we may provide on specific occasions when we are collecting or processing personal data about you so that you are fully aware of how and why we are using your data. This privacy notice supplements any other notices and is not intended to override them. 
                </p>
                <h4>Controller</h4>
                <p>
                  Weeley Veterinary Centre is the data controller and responsible for your personal data (collectively referred to as “Weeley Veterinary Centre”, "we", "us" or "our" in this privacy notice).<br />
                  <br />
                  Our full details are:<br />
                  <br />
                  <table className="table table-striped table-hover">
                    <tr>
                      <th colSpan={2}>Contact details</th>
                    </tr>
                    <tr>
                      <td className="align-top">Registered Office:</td>
                      <td>Weeley Veterinary Centre<br />26 Colchester Road,<br /> Weeley,<br /> Essex,<br /> CO16 9JT.</td>
                    </tr>
                    <tr>
                      <td className="align-top">Registered in England &amp; Wales:</td>
                      <td>13421283</td>
                    </tr>
                    <tr>
                      <td className="align-top">VAT Registration No:</td>
                      <td>GB 385121796</td>
                    </tr>
                    <tr>
                      <td className="align-top">Data Compliance Officer Email address:</td>
                      <td><a href="mailto:manager@weeleyvetcentre.com" className="p-0">manager@weeleyvetcentre.com</a></td>
                    </tr>
                    <tr>
                      <td className="align-top">Postal address:</td>
                      <td>26 Colchester Road, Weeley, Essex, CO16 9JT</td>
                    </tr>
                  </table>   
                  You have the right to make a complaint at any time to the Information Commissioner's Office (ICO), the UK supervisory authority for data protection issues (www.ico.org.uk). We would, however, appreciate the chance to deal with your concerns before you approach the ICO, so please contact us in the first instance.<br />
                </p>
                <h4>Changes to the privacy notice and your duty to inform us of changes</h4>
                <p>
                  This version was last updated on 16 July 2022 and historic versions can be obtained by contacting us. It is important that the personal data we hold about you is accurate and up-to-date. Please keep us informed if your personal data changes during your relationship with us.
                </p>
                <h4>Links to third-party websites</h4>
                <p>
                  Our website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy notice of every website you visit.
                </p>
                <h3 id="2">2. The data we collect about you</h3>
                <p>
                  Personal data, or personal information, means any information about an individual (the ‘Data Subject’) from which that person can be identified. It does not include data where the identity has been removed (anonymous data).<br />
                  <br />
                  We may collect, use, store and transfer different kinds of personal data about you which we have grouped together follows:<br />
                  <br />
                  <ul className="list-group">
                    <li className="list-group-item">Identity Data including first name, maiden name, last name, username or similar identifier, marital status, title, date of birth and gender.</li>
                    <li className="list-group-item">Contact Data including billing address, delivery address, email address and telephone numbers.</li>
                    <li className="list-group-item">Pet Data including the name, species, breed, gender, insurance records and medical history of your pet(s).</li>
                    <li className="list-group-item">Financial Data including bank account and payment card details.</li>
                    <li className="list-group-item">Transaction Data including details about payments to and from you and other details of products and veterinary services you have purchased from us.</li>
                    <li className="list-group-item">Technical Data including internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform and other technology on the devices you use to access our website.</li>
                    <li className="list-group-item">Profile Data including your username and password, purchases or orders made by you, your interests, preferences, feedback and survey responses.</li>
                    <li className="list-group-item">Usage Data including information about how you use our website, products and veterinary services.</li>
                    <li className="list-group-item">Marketing and Communications Data including your preferences in receiving marketing from us and our third parties and your communication preferences.</li>
                  </ul>
                  <br />
                  We also collect, use, and share Aggregated Data such as statistical or demographic data for certain purposes. Aggregated Data may be derived from your personal data but is not considered personal data in law, as this data does not directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific website feature. However, if we combine or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this privacy notice.<br />
                  <br />
                  We do not collect any Special Categories of Personal Data about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health, and genetic and biometric data). Nor do we collect any information about criminal convictions and offences.<br />
                  <br />
                  Where we need to collect personal data by law, or under the terms of a contract we have with you, and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you (for example, to provide you with products or veterinary services). In this case, we may have to cancel a product or service you have with us. We will notify you if this is the case at the time.
                </p>
                <h3 id="3">3. How is your personal data collected?</h3>
                <p>
                  We use different methods to collect data from and about you, including:<br />
                  <br />
                  <ul className="list-group">
                    <li className="list-group-item">
                      Direct interactions. You may give us your Identity, Contact, Pet and Financial Data by filling in forms or by corresponding with us by post, phone, email or otherwise. This includes personal data you provide when you:<br />
                      <ul>
                        <li>register with one of our practices;</li>
                        <li>request our products or services;</li>
                        <li>create an account on our website;</li>
                        <li>subscribe to our publications;</li>
                        <li>request marketing to be sent to you;</li>
                        <li>enter a competition, promotion or survey; or</li>
                        <li>give us feedback on our products or services.</li>
                      </ul>
                    </li>
                    <li className="list-group-item">Automated technologies or interactions As you interact with our website, we may automatically collect Technical Data about your equipment, browsing actions and patterns. We collect this personal data by using cookies and other similar technologies.</li>
                    <li className="list-group-item">Third parties or publicly available sources. We may receive personal data about you from various third parties [and public sources] as set out below.</li>
                    <li className="list-group-item">Please be aware we record both incoming and outgoing phone calls for training purposes and as a record of patient and client care. These recordings are treated in accordance with GDPR.</li>
                    <li className="list-group-item">CCTV in use</li>
                  </ul>
                </p>
                <h3 id="4">4. How we use your personal data</h3>
                <p>
                  We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:<br />
                  <br />
                  <ul className="list-group">
                    <li className="list-group-item">Where we are about to enter or have entered into a contract for the delivery of veterinary services with you.</li>
                    <li className="list-group-item">Where processing is necessary for our legitimate interests (or those of a third party) as well as your interests, and your fundamental rights and freedoms do not override those interests.</li>
                    <li className="list-group-item">Where we need to comply with a legal or regulatory obligation.</li>
                  </ul>
                  <br />
                  Please refer to the Glossary to find out more about the types of lawful basis that we will rely on to process your personal data. Generally, we do not rely on consent as a legal basis for processing your personal data other than in relation to sending third party direct marketing communications to you via email or text message. You have the right to withdraw consent to marketing at any time by contacting us.
                </p>
                <h4>Purposes for which we will use your personal data</h4>
                <p>
                  We have set out below, in a table format, a description of all the ways we plan to use your personal data, and which of the legal bases we rely on to do so. We have also identified what our legitimate interests are (where appropriate). Note that we may process your personal data on more than one lawful ground depending on the specific purpose for which we are using your data. If you would like to know more about the specific legal ground we are relying on to process your personal data (where more than one ground has been set out), please contact us. 
                </p>
                <h4>Marketing</h4>
                <p>
                  We may process your personal data in order to make suggestions and recommendations to you about products or services that may be of interest to you (‘direct marketing’). In these cases, the lawful basis we rely on is ‘consent’. Before we process your data for direct marketing purposes, we will require your consent to do so. Consent means any freely given, specific, informed and unambiguous indication of your wishes by which you, by a statement or by a clear affirmative action, signify agreement to the processing of personal data relating to yourself.
                </p>
                <h4>Promotional offers from us</h4>
                <p>
                  We may use your Identity, Contact, Technical, Usage and Profile Data to form a view on what we think you may want or need for your pet(s), or what may be of interest to you. This is how we decide which products, services and offers may be relevant for you (we call this marketing).<br/>
                  <br />
                  You will only receive marketing communications from us if you have purchased products or services from us and specifically ‘opted in’ to receive such communications. 
                </p>
                <h4>Third-party marketing</h4>
                <p>
                  We will get your express opt-in consent before we share your personal data with any other company for marketing purposes. 
                </p>
                <h4>Opting out</h4>
                <p>
                  You can ask us or third parties to stop sending you marketing messages at any time or adjust your marketing preferences or by following the opt-out or ‘unsubscribe’ links on any marketing message sent to you or by contacting us at any time. 
                </p>
                <h4>Cookies</h4>
                <p>
                  Our website uses essential cookies to enable core functionalities such as security, network management, and accessibility. Our website may also use non-essential cookies, such as Google Analytics, to help us to improve our website by collecting and reporting information on how you use it. Finally, our website may use marketing cookies in order to promote products which you may find interesting. These cookies will in almost all cases only collect anonymous data. For more information about the cookies we use, and how to disable them, please see Cookie Policy.
                </p>
                <h4>Change of purpose</h4>
                <p>
                  We will only use your personal data for the purposes for which we collected it. However, we may sometimes find that our purposes for collecting and using data changes over time. If we originally collected and used your personal data on the lawful basis of consent, we will always ask you for ‘fresh’ consent for the processing of your personal data for any new purpose. If we did not originally collect your personal data on the basis of consent, we may only use your personal data for a new purpose if this new purpose is compatible with the original purpose. When assessing whether the original and new purpose are compatible, we will consider the differences between the original and the new purpose; the reasonable expectations that you would have with regards to our handling of your data; and the impact on the individuals whose data we are processing. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose, please contact us.<br />
                  <br />
                  If we need to use your personal data for an unrelated, ‘incompatible’ purpose, we will notify you and we will explain the legal basis which allows us to do so.
                </p>
                <h3 id="5">5. Disclosures of your personal data</h3>
                <p>
                  We may have to share your personal data with the parties set out below for the purposes set out in the table in paragraph 4 above.<br />
                  <br />
                  <ul className="list-group">
                    <li className="list-group-item">External Third Parties as set out in the Glossary.</li> 
                    <li className="list-group-item">Specific third parties such as our Practice Management System, our website builder, and our communications provider. If you would like to have more information about these specific third parties please contact us.</li>
                    <li className="list-group-item">Third parties to whom we may choose to sell, transfer, or merge parts of our business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this privacy notice.</li>
                    <li className="list-group-item">Where this is required by law, we may share your personal data with relevant law enforcement agencies without your knowledge or consent. We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions, set out in a Data Sharing Agreement.</li>
                  </ul>
                </p>
                <h3 id="6">6. International transfers</h3>
                <p>
                  We do not transfer your personal data outside the European Economic Area (EEA) without having appropriate safeguards in place to allow for restricted transfers of personal data.
                </p>
                <h3 id="7">7. Data security</h3>
                <p>
                  We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.<br />
                  <br />
                  We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.
                </p>
                <h3 id="8">8. Data retention</h3>
                <p>
                  How long will you retain my personal data? We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.  To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements. We keep basic information about our customers (including Contact, Identity, Pet, Financial and Transaction Data) for six years after they cease being customers for legal and tax purposes. In some circumstances you can ask us to delete your data: see Request erasure below for further information. In some circumstances we may anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes, in which case we may use this information indefinitely without further notice to you. 
                </p>
                <h3 id="9">9. Your legal rights</h3>
                <br />
                <p>
                  Under certain circumstances, you have rights under data protection laws in relation to your personal data.<br />
                  <br />
                  <ul className="list-group">
                    <li className="list-group-item">Request access to your personal data (commonly known as a "data subject access request"). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.</li>
                    <li className="list-group-item">Request correction of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.</li>
                    <li className="list-group-item">Request erasure of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.</li>
                    <li className="list-group-item">Object to processing of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.</li>
                    <li className="list-group-item">Request restriction of processing of your personal data. This enables you to ask us to suspend the processing of your personal data in the following scenarios: (a) if you want us to establish the data's accuracy; (b) where our use of the data is unlawful but you do not want us to erase it; (c) where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims; or (d) you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.</li>
                    <li className="list-group-item">Request the transfer of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.</li>
                    <li className="list-group-item">Withdraw consent at any time where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain services to you. We will advise you if this is the case at the time you withdraw your consent.</li>
                  </ul>
                  <br />
                  If you wish to exercise any of the rights set out above, please contact us.   <br />
                  <br />
                  You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in these circumstances.<br />
                  <br />
                  We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.<br />
                  <br />
                  We are usually able to respond to all requests within one calendar month from confirming the validity of the request. In the rare occasions where a request is particularly complex or voluminous, we may need to extend this deadline by an additional two calendar months. If this is the case, we will notify you as soon as possible, and in any event within 30 days.
                </p>
                <h3 id="10">10. Glossary</h3>
                <h4>LAWFUL BASIS</h4>
                <p>
                  Legitimate Interest means the interest of our business in conducting and managing our business to enable us to give you the best service/product and the best and most secure experience. We make sure we consider and balance any potential impact on you (both positive and negative) and your rights before we process your personal data for our legitimate interests. We do not use your personal data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to do so by law). You can obtain further information about how we assess our legitimate interests against any potential impact on you in respect of specific activities by contacting us.<br />
                  <br />
                  Performance of Contract means processing your data where it is necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract.<br />
                  <br />
                  Comply with a legal or regulatory obligation means processing your personal data where it is necessary for compliance with a legal or regulatory obligation that we are subject to.<br />
                </p>
                <h4>THIRD PARTIES</h4>
                <p>
                  External Third Parties<br />
                  <br />
                  <ul className="list-group">
                    <li className="list-group-item">Service providers [acting as processors] based in the United Kingdom and the EEA who provide IT and system administration services.</li>
                    <li className="list-group-item">Service providers [acting as processors] based in non-EEA countries where appropriate safeguards have been put in place to allow for restricted transfers of personal data.</li>
                    <li className="list-group-item">Professional advisers including lawyers, bankers, auditors and insurers based the United Kingdom and the EEA who provide consultancy, banking, legal, insurance, debt and accounting services.</li>
                    <li className="list-group-item">HM Revenue &amp; Customs, regulators and other authorities based in the United Kingdom who require reporting of processing activities in certain circumstances.</li>
                  </ul>
                </p>
                  Data Protection Impact Assessment<br />
                  <br />
                  Please see our <a href="DPIACCTV.docx">DPIA document</a> for details.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
