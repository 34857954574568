import React from 'react';
import Header from '../header/Header';
import hero from '../img/april.jpg';
import { NavLink, Outlet } from "react-router-dom";

function About() {
  return (
    <div className="container">
      <div className="row p-0 rounded-3 border shadow-lg">
        <div className="col-md-3 col-xl-2 bg-dark rounded-start">
          <ul className="nav nav-pills nav-fill pt-2 pb-2" id="menu">
            <li className="nav-item">
              <NavLink to="story" className={({ isActive }) => isActive ? "nav-link active" : "nav-link text-white"}>
                Our Story
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="concept" className={({ isActive }) => isActive ? "nav-link active" : "nav-link text-white"}>
                The Concept
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="reviews" className={({ isActive }) => isActive ? "nav-link active" : "nav-link text-white"}>
                Reviews
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="col-md-9 col-xl-10 min-vh-100 rounded-end">
          <Header />
          <div className="row">
            <div className="col-md-8 p-5">
              <Outlet />
            </div>
            <div className="col-md-4 p-0 pt-5">
              <div className="card me-3 mb-3 p-0 text-centre">
                <img className="card-img" src={hero} alt="" width="300" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
