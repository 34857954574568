import React from 'react';
import { NavLink, Outlet } from "react-router-dom";
import RegisterService from '../api-services/RegisterService';
import Header from '../header/Header';

function Services() {
  const service = RegisterService();
  return (
    <div className="container">
      <div className="row p-0 rounded-3 border shadow-lg">
        <div className="col-md-3 col-xl-2 bg-dark rounded-start">
          <ul className="nav nav-pills nav-fill pt-2 pb-2" id="menu">
            {service.status === 'loading' && <div>Loading...</div>}
            {service.status === 'loaded' && (
              <li className="nav-item">
                <NavLink to="register" className={({ isActive }) => isActive ? "nav-link active" : "nav-link text-white"}>
                  {service.payload.title}
                </NavLink>
              </li>
            )}
            {service.status === 'error' && (<div>Error, the backend moved to the dark side.</div>)}
            <li className="nav-item">
              <NavLink to="appointment" className={({ isActive }) => isActive ? "nav-link active" : "nav-link text-white"}>
                Book an Appointment
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="medication" className={({ isActive }) => isActive ? "nav-link active" : "nav-link text-white"}>
                Order Repeat Medication
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="vip" className={({ isActive }) => isActive ? "nav-link active" : "nav-link text-white"}>
                VIP Club
              </NavLink>
            </li>
            {/*<li className="nav-item">
              <NavLink to="visits" className={({ isActive }) => isActive ? "nav-link active" : "nav-link text-white"}>
                Home Visits
              </NavLink>
            </li>*/}
            <li className="nav-item">
              <NavLink to="goodbye" className={({ isActive }) => isActive ? "nav-link active" : "nav-link text-white"}>
                Saying Goodbye
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="prices" className={({ isActive }) => isActive ? "nav-link active" : "nav-link text-white"}>
                Our Prices
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="col-md-9 col-xl-10 rounded-end">
          <Header />
          <div className="row">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
