import React from 'react';
import logo from '../img/logo.png';

function Sustainability() {
  return (
    <div className="container">
      <div className="row p-0 rounded-3 border shadow-lg">
        <div className="col-md-9 col-xl-10 min-vh-100 rounded-end">
          <div className="row rounded-end">
            <div className="text-centre">
              <img src={logo} alt="Weeley Vet Centre" className="align-centre img-fluid" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 p-5">
              <div className="row">
                <h3>Sustainability Policy</h3>
                <br />
                <p>Bringing requirements to life</p>
                <ul>
                  <li>Some requirements focus on laying the foundations for a practices sustainability journey:</li>
                  <li>The practice has a written environmental sustainability policy (core) - Environmental policy is with the health and safety folder</li>
                  <li>The practice has appointed a sustainability champion or created a sustainability team (GP) - Our Champion is Aiyesha Barker and our  Green Team includes Dr April Young and Practice Manager.</li>
                  <li>The practice must have a system in place for team members to suggest sustainability ideas and improvements (GP) - Team members can suggest sustainability ideas to our champion Aiyesha and via WhatsApp as that is more environmentally friendly that paper.</li>
                </ul>

                <ul>
                  <li>The practice must have an environmental sustainability policy which shows senior commitment e.g. signed by practice manager/director. - Signed by both Director and Practice Manager</li>
                  <li>Policy should be available to view e.g. on website. - Will go onto Website at next update </li>
                  <li>Policy must be shared with all team members e.g. in staff meeting/via email. - All team members made aware via WhatsApp</li>
                </ul>
 
                <p>Assessors will want to see evidence such as:</p>
                <ul>
                  <li>Nominated sustainability champion in the sustainability policy. - Champion is Aiyesha Barker</li>
                  <li>For solo vets, having a written sustainability policy is enough as it is assumed they are the sustainability champion. </li>
                  <li>Green group that meet regularly - Green Team = Dr April Young, Verity Theobald & Aiyesha Barker</li>
                  <li>Opportunity to add sustainability ideas to team meeting agenda. - Always welcome. We are a small team and everyone shares ideas regularly on all aspects of practice life including sustainability</li>
                  <li>Raising suggestions through nominated sustainability champion/team. - Aiyesha Barker</li>
                </ul>
 
                <ul>
                  <li>Social media posts - Green roof and Knauf board </li>
                  <li>Waiting room displays - We do not have displays in waiting area</li>
                </ul>


                <p>To achieve this Practices must show awareness of how their mileage and/or clients mileage can be reduced through appointment planning. Assessors do not need physical evidence but will speak to team members to understand their awareness and how routine appointments are planned to avoid unnecessary mileage.</p>
                <ul>
                  <li>We consolidate appointments to avoid unnecessary journeys by performing medication checks at time of vaccines.</li>
                </ul>


                <p>To achieve this the practice should show evidence of advising clients on returning unused medications to the practice for safe disposal.</p>
                <ul>
                  <li>Our team members inform clients when they pick up prescriptions </li>
                </ul>

                <p>To achieve this Practices should be able to demonstrate awareness of the environmental impact of anaesthetic gases and highlight one way in which they are addressing it. Assessors will talk to team members to understand awareness with evidence of ways the practice is addressing anaesthetic gas usage e.g.</p>
                <ul>
                  <li>Using local/regional anaesthetic where appropriate: SOP for use </li>
                  <li>Optimising flow rate: guidance/training on flow rates for practice team </li>
                  <li>Reducing nitrous oxide usage, switch to sevofluorane from isofluorane: evidence of reduction in use or policy to stop/ reduce use. </li>
                  <li>Increased use PIVA/TIVA where clinically appropriate: SOP/training </li>
                </ul>

                <p>Offset against Practice Manager's electric car. We use oygen concentrators as a way to reduce carbon footprint ass with oxygen cylinder delivery.</p>

                <p>To achieve this The practice should be able to show that they offer at least one resource on preventative healthcare. Assessors will want to see evidence such as:</p>
                <ul>
                  <li>Information on the website with resources on preventative healthcare - VIP Club, we offer dental, diabetes and obesity clinics</li>
                </ul>

                <p>The practice undertakes a waste survey annually and can demonstrate that the results are analysed and, where appropriate, action has been taken as a result</p>
 

                <p>To achieve this Assessors will talk to team members and want to see evidence such as:</p>
                <ul>
                  <li>Ectoparasiticides policy - Vets recommend flea and worm treatments specific to the individual patient.</li>
                  <li>Guidance/use of a client decision making tool </li>
                  <li>Waiting room poster - Leaflet available</li>
                </ul>

                <h4>Environmental and sustainability at WVC</h4>
                <ul>
                  <li>Green roof</li>
                  <li>Bee friendly garden with wild flowers</li>
                  <li>Knaufboard instead of lead lined X-ray room</li>
                  <li>Recycle as much as possible including cardboard, batteries and empty toner cartridges</li>
                  <li>Turn off lights when not in use</li>
                  <li>Heating turned down by two degrees</li>
                  <li>We reuse second hand furniture and equipment</li>
                  <li>Our team walk, cycle and drive electric car. We have bicycle parking</li>
                  <li>We reduce paper by using online messaging, email and WhatsApp</li>
                  <li>We have in-house practice meetings</li>
                  <li>Avoid drug wastage by good stocking principles</li>
                  <li>We use Easy Direct Debits who plant two trees every month</li>
                  <li>We use reusable drapes and caps</li>
                  <li>We dry our washing outside on the line when we can to reduce tumble dryer usage</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sustainability;
