import React from 'react';

function Story() {
  return (
    <div className="row">
      <h3 id="story">Our Story</h3>
      <p className="lead">
Weeley Veterinary Clinic is the brainchild of Dr April Young MRCVS who has lived and worked in our local community since 2012.<br />
<br />
“I realised that in order to care for my beloved patients and owners to the high standard I demand of myself every single day, I needed to start my own independent veterinary practice. For me being a vet is never just a job, it is and always will be a vocation.<br />
<br />
I created Weeley Veterinary Centre so that I and my exceptional team are able to deliver the service you and your pets deserve. Above all we are a practice that truly believes in personal, individualised patient and client care. We know and care about our clients, their pets are the core of our practice and treated as part of our own family.<br />
<br />
Weeley Veterinary Centre is the practice the pet owning public has been waiting for, providing simply excellent veterinary care from local people you can trust.”<br />
      </p>
      <br />
    </div>
  );
}

export default Story;
