import React, { useEffect, useRef } from 'react';
import hero from '../img/hero.png';
import { NavLink } from "react-router-dom";
import RegisterService from '../api-services/RegisterService';

function Awards() {
  return (
    <div className="container">
      <div className="row p-4 py-4 rounded-3 border shadow-lg">
        <div className="col-lg-12">
          <div className="card p-0 text-centre">
            <img src="/img/awards/Outdoors1.jpeg" alt="awards" className="card-img img-fluid"/>
          </div>
          <p className="lead">The BestUKVets Awards celebrate veterinary practices that delight their clients and are presented to practices across the UK with the most 4 or 5 star online reviews in each category.</p>

          <p className="lead">During our opening year we hoped we might win the award for Best New Start-Up and were absolutely delighted when we did. However that wasn't all as we won several awards…

          <ul>
            <li>Best New Start-Up</li>
            <li>Top 25</li>
            <li>Best in Essex</li>
            <li>Best in England</li>
          </ul>

          And the overall gold award making us the Best Vet in the UK. </p>

          <p className="lead">We are the first practice to win the gold award in their opening year and are so grateful to every client who wrote a review for us.</p>

          <p className="lead">As the overall winner, we were invited to two awards presentations. Myself, Verity and Ness represented the team at the official awards ceremony in London, attended by over 150 veterinary professionals.</p>

          <div className="card p-0 text-centre">
            <img src="/img/awards/London1.jpeg" alt="awards" className="card-img img-fluid"/>
          </div>
          <div className="card p-0 text-centre">
            <img src="/img/awards/London2.jpeg" alt="awards" className="card-img img-fluid"/>
          </div>
          <div className="card p-0 text-centre">
            <img src="/img/awards/London3.jpeg" alt="awards" className="card-img img-fluid"/>
          </div>

          <p className="lead">The following day we held a local celebration with our entire team and as many pet owners as the village hall could comfortably accommodate.</p>

          <div className="card p-0 text-centre">
            <img src="/img/awards/Weeley1.jpeg" alt="awards" className="card-img img-fluid"/>
          </div>
          <div className="card p-0 text-centre">
            <img src="/img/awards/Weeley2.jpeg" alt="awards" className="card-img img-fluid"/>
          </div>

          <p className="lead">"The BestUKVets Awards were established to reward vet practices for outstanding feedback from pet owning clients. We are delighted for April and Weeley Veterinary Centre staff. Having read so many of their wonderful reviews, it is no surprise that they won. Local pet owners are very fortunate to have this new practice.” Susie Samuel, CEO of VetHelpDirect</p>

          <div className="card p-0 text-centre">
            <img src="/img/awards/Team1.jpeg" alt="awards" className="card-img img-fluid"/>
          </div>

          <p className="lead">Thank you to all our wonderful clients for their support and to my incredible colleagues who have helped me to create the best practice I could ever have hoped for. This award is a reflection of all their hard work and commitment to you and your pets.</p>

          <p className="lead">Kind regards,</p>
          <p className="lead">April</p><br /><br />

          <h2 className="display-7"><span className="bg-success text-light rounded px-2">Update 2024… We did it again!</span></h2><br />

          <p className="lead">This year's awards ceremony took place in Bristol. I attended with Gemma, Aiyesha and Tegan, and am delighted to say we dominated for the second year in a row. We were crowned Best Small Vet UK 2024 and received an armful of trophies, including Best New Vet UK. A highlight was being presented with the very special Elite Award in recognition of our commitment to maintaining our exceptional standards. It was a fantastic day and we celebrated back in Essex with a whole team photoshoot and celebratory lunch.</p>

          <div className="card p-0 text-centre">
            <img src="/img/awards/Awards1.jpeg" alt="awards" className="card-img img-fluid"/>
          </div>

          <div className="card p-0 text-centre">
            <img src="/img/awards/Team3.jpeg" alt="awards" className="card-img img-fluid"/>
          </div>

          <div className="card p-0 text-centre">
            <img src="/img/awards/Team4.jpeg" alt="awards" className="card-img img-fluid"/>
          </div>

          <p className="lead">I would once again like to thank every single person who wrote a review for us. We could not have done it without you.</p>

          <p className="lead">Kind regards,</p>
          <p className="lead">April</p><br /><br />
        </div>
      </div>
    </div>
  );
}

export default Awards;
