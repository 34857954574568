import React from 'react';
import { NavLink, Outlet } from "react-router-dom";
import { TeamCategoryService } from '../api-services/TeamService';
import Header from '../header/Header';

function Team() {
  const service = TeamCategoryService();
    
  return (
    <div className="container">
      {service.status === 'loading' && <div>Loading...</div>}
      {service.status === 'loaded' && (
        <div className="row p-0 rounded-3 border shadow-lg">
          <div className="col-md-3 col-xl-2 bg-dark rounded-start">
            <ul className="nav nav-pills nav-fill pt-2 pb-2" id="menu">
              { service.payload.map((category) => 
                <li className="nav-item" key={category.code}>
                  <NavLink to={"/team/" + category.code} className="nav-link text-white">
                    {category.description}
                  </NavLink>
                </li>
              )}
              <li className="nav-item">
                <NavLink to="workwithus" className={({ isActive }) => isActive ? "nav-link active" : "nav-link text-white"}>
                  Work with us
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="col-lg-9 p-5 pt-0">
            <Header />
            <Outlet />
          </div>
        </div>
      )}
      {service.status === 'error' && (<div>Error, the backend moved to the dark side.</div>)}
    </div>
  );
}

export default Team;
