import React from 'react';

function WorkWithUs() {
  return (
    <div className="container">
      <div className="row">
        <h3 id="workwithus">Work with us</h3>
      </div>
      <div className="row p-0 pb-3">
        <p>
A veterinary practice would be nothing without its team and ours is truly exceptional. We are a group of happy and enthusiastic people who passionately believe we belong to the best profession in the world.<br />
<br />
We are always happy to hear from future colleagues (vets, nurses and receptionists). If you'd like to join our team please send your CV and a cover letter to <a href="mailto:manager@weeleyvetcentre.com">manager@weeleyvetcentre.com</a><br />
<br />
We also strongly believe in supporting students through EMS and work experience placements.  If you are interested in a placement, please email <a href="mailto:manager@weeleyvetcentre.com">manager@weeleyvetcentre.com</a><br />
        </p>
      </div>
    </div>
  );
}

export default WorkWithUs;
