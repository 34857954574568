import React from 'react';
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="container">
      <span className="text-muted">Copyright &copy; Weeley Veterinary Centre.</span>&nbsp;
      <div className="vr"></div>&nbsp;<Link to="privacy">Privacy Policy</Link>&nbsp;
      <div className="vr"></div>&nbsp;<Link to="environmental">Environmental Policy</Link>&nbsp;
      <div className="vr"></div>&nbsp;<Link to="sustainability">Sustainability Policy</Link>&nbsp;
      <div className="vr"></div>&nbsp;<Link to="debt">Debt Recovery Policy</Link>&nbsp;
    </div>
  );
}

export default Footer;
