import React from 'react';
import logo from '../img/logo.png';
import { NavLink } from "react-router-dom";

function NotFound() {
  return (
    <div className="px-4 py-5 my-5 text-center">
      <img className="d-block mx-auto mb-4 img-fluid" src={logo} alt="Weeley Vet Centre" />
      <h1 className="display-5 fw-bold">404 Not Found</h1>
      <div className="col-lg-6 mx-auto">
        <p className="lead mb-4">Sorry, an error has occured, Requested page not found!</p>
        <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
          <NavLink to="/" className="btn btn-primary btn-lg px-4 gap-3">Home</NavLink>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
