import React from 'react';
import './App.css';
import WeeleyNavBar from './navbar/WeeleyNavBar';
import Footer from './footer/Footer';
import { Outlet } from "react-router-dom";

function App() {
  return (
    <div>
      <header>
        <WeeleyNavBar />
      </header>
      <main className="flex-shrink-0 p-main">
        <Outlet />
      </main>
      <footer className="footer mt-auto py-3 bg-light fixed-bottom">
        <Footer />
      </footer>
    </div>
  );
}

export default App;
