import { useEffect, useState } from 'react';
import { Service } from '../api-objects/Service';
import { PricingSection } from '../api-objects/PricingSection';

const PricingService = () => {
  const [result, setResult] = useState<Service<PricingSection[]>>({
    status: 'loading'
  });

  useEffect(() => {
    fetch('/api/prices.json')
      .then(response => response.json())
      .then(response => setResult({ status: 'loaded', payload: response }))
      .catch(error => setResult({ status: 'error', error }));
  }, []);

  return result;
};

export default PricingService;