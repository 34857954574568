import React, { useState } from 'react';
import { useForm, SubmitHandler } from "react-hook-form";
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

type Inputs = {
  petsName: string,
  ownersName: string,
  postcode: string,
  phone: string,
  email: string,
  medication: string,
  comments: string
};

function Medication() {
  const [message, setMessage] = useState("");
  const [showFailure, setShowFailure] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const { register, handleSubmit, watch, formState: { errors } } = useForm<Inputs>();
  
  const onSubmit: SubmitHandler<Inputs> = data => {
    (async () => {
      const fetchResult = await fetch('/smtp.php', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      
      if (fetchResult.ok) {
        const content = await fetchResult.text();
        setMessage(content);
        setShowFailure(false);
        setShowSuccess(true);
      } else {
        setMessage(fetchResult.status + ": " + fetchResult.statusText);
        setShowSuccess(false);
        setShowFailure(true);
      }

    })();
  };

  return (
    <div className="container">
      <div className="row p-3">
        <h3 id="medication">Order Repeat Medication</h3>
      </div>
      <div className="row p-3 pb-3">
        <p>
For a repeat veterinary medicines prescription, please complete the form below. Our reception team will contact you once your prescription has been put up, please allow 2 working days for this.<br />
<br />
Repeat prescriptions are only for patients already under the care of Weeley Veterinary Centre. To comply with RCVS regulations and ensure your pet is still receiving the most appropriate treatment for their condition, all animals must be examined by a vet at least once every 6 months. Some animals will require more frequent appointments.<br />
<br />
We will advise you if your pet is due a check-up.<br />
        </p>
        <div className="card">
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Alert show={showFailure} variant="danger">
                <Alert.Heading>Error!</Alert.Heading>
                <p>
                  {message}
                </p>
                <hr />
                <div className="d-flex justify-content-end">
                  <Button onClick={() => setShowFailure(false)} variant="outline-danger">
                    Dismiss
                  </Button>
                </div>
              </Alert>
              <Alert show={showSuccess} variant="success">
                <Alert.Heading>Success</Alert.Heading>
                <p>
                  {message}
                </p>
                <hr />
                <div className="d-flex justify-content-end">
                  <Button onClick={() => setShowSuccess(false)} variant="outline-success">
                    Dismiss
                  </Button>
                </div>
              </Alert>
              <div className="row">
                <div className="col position-relative">
                  <label htmlFor="petsNameInput" className="form-label">Pets Name</label>
                  <input type="text" className="form-control" id="petsNameInput" {...register("petsName", { required: true })} />
                  {errors.petsName && <div className="invalid-tooltip" style={{display: "block"}}>This field is required</div>}
                </div>
                <div className="col position-relative">
                  <label htmlFor="ownersName" className="form-label">Your Name and Surname</label>
                  <input type="text" className="form-control" id="ownersName" {...register("ownersName", { required: true })} />
                  {errors.ownersName && <div className="invalid-tooltip" style={{display: "block"}}>This field is required</div>}
                </div>
                <div className="col position-relative">
                  <label htmlFor="postcode" className="form-label">Address Postcode</label>
                  <input type="text" className="form-control" id="postcode" {...register("postcode", { required: true })} />
                  {errors.postcode && <div className="invalid-tooltip" style={{display: "block"}}>This field is required</div>}
                </div>
              </div>
              <div className="row">
                <div className="col position-relative">
                  <label htmlFor="phone" className="form-label">Phone Number</label>
                  <input type="tel" className="form-control" id="phone" {...register("phone", { required: true })} />
                  {errors.phone && <div className="invalid-tooltip" style={{display: "block"}}>This field is required</div>}
                </div>
                <div className="col position-relative">
                  <label htmlFor="emailInput" className="form-label">Email address</label>
                  <input type="email" className="form-control" id="emailInput" aria-describedby="emailHelp" {...register("email", { required: true })} />
                  <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
                  {errors.email && <div className="invalid-tooltip" style={{display: "block"}}>This field is required</div>}
                </div>
                <div className="col position-relative">
                  <label htmlFor="medication" className="form-label">Name of Medication Required</label>
                  <input type="text" className="form-control" id="medication" {...register("medication", { required: true })}/>
                  {errors.medication && <div className="invalid-tooltip" style={{display: "block"}}>This field is required</div>}
                </div>
              </div>
              <div className="row pb-2">
                <div className="col position-relative">
                  <label htmlFor="comments" className="form-label">Additional Comments</label>
                  <textarea className="form-control" id="comments" rows={3} {...register("comments")}></textarea>
                </div>
              </div>
              
              <button type="submit" className="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Medication;
