import React from 'react';
import { TeamMember } from '../api-objects/TeamMember';

const divStyle = {
  width: '18rem'
};

function TeamMemberComponent(teamMember: TeamMember) {
  return (
    <div className="card me-3 mb-3 p-0" style={divStyle}>
      <img src={"/img/" + teamMember.picture} className="card-img-top" alt={teamMember.name} />
      <div className="card-body">
        <h5 className="card-title">{teamMember.name}</h5>
        <p className="card-text" style={{whiteSpace: "pre-wrap"}}>{teamMember.bio}</p>
      </div>
    </div>
  );
}

export default TeamMemberComponent;
