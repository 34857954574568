import PricingService from '../api-services/PricingService'

function Prices() {
  const service = PricingService();

  return (
    <div>
      <div className="row">
        <h3 id="concept">Our Prices</h3>
        <p className="lead">When choosing the best treatment for your pet, we understand how important it is to know the expected cost before we proceed. We tailor treatments to our individual patients and no two are the same so it isn't possible to give a list of prices for everything, however these are our most commonly requested fixed prices:</p>
      </div>
      {service.status === 'loading' && <div>Loading...</div>}
      {service.status === 'loaded' && (
        service.payload.map((section) =>
          <div key={section.title}>
            <h4>{section.title}</h4>
            <p><span className="mb-1 text-muted" style={{whiteSpace: "pre-wrap"}}>{section.before}</span></p>
            {section.categories.map((category) => 
              <ul className="list-group pb-3">
                <li className="list-group-item list-group-item-primary">{category.title}</li>
                {category.prices.map((price) => 
                  <li className="list-group-item d-flex justify-content-between lh-sm">
                    <div>{price.description}</div>
                    <span className="text-muted">{price.price}</span>
                  </li>
                )}
              </ul>
            )}
            <p><span className="mb-1 text-muted" style={{whiteSpace: "pre-wrap"}}>{section.after}</span></p>
          </div>
        )
      )}
      {service.status === 'error' && (<div>Error, the backend moved to the dark side.</div>)}
    </div>
  );
}

export default Prices;