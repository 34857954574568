import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { Link } from "react-router-dom";
import ConfigurationService from '../api-services/ConfigurationService'
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import RegisterService from '../api-services/RegisterService';

library.add(fas, fab)

const WeeleyNavBar: React.FC<{}> = () => {
  const [active, setActive] = useState('default');
  const service = ConfigurationService();
  const registerService = RegisterService();
  
  return (
    <Navbar variant="dark" expand="xl" fixed="top" className="bg-weeley" collapseOnSelect>
      {service.status === 'loading' && <div>Loading...</div>}
      {service.status === 'loaded' && (
        <Container fluid>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="navbar-nav me-auto mb-2 mb-md-0">
              <Nav.Item>
                <a className="nav-link" href={"tel:" + service.payload.phone}><FontAwesomeIcon icon="phone" size="lg" />&nbsp;{service.payload.phone}</a>
              </Nav.Item>
              <Nav.Item>
                <a className="nav-link" href={"mailto:" + service.payload.email}><FontAwesomeIcon icon="envelope" size="lg" />&nbsp;{service.payload.email}</a>
              </Nav.Item>
              <Nav.Item>
                <a className="nav-link" href={service.payload.facebook}><FontAwesomeIcon icon={['fab', 'facebook-f']} size="lg" />&nbsp;Facebook</a>
              </Nav.Item>
              {registerService.status === 'loading' && <div>Loading...</div>}
              {registerService.status === 'loaded' && (
                <Nav.Item>
                  <Nav.Link to="/services/register" eventKey="register" as={Link} className="btn btn-success"><FontAwesomeIcon icon={['fas', 'user-plus']} size="lg" />&nbsp;{registerService.payload.title}</Nav.Link>
                </Nav.Item>
              )}
              {registerService.status === 'error' && (<div>Error, the backend moved to the dark side.</div>)}
            </Nav>
            <Nav className="navbar-nav d-flex" activeKey={active} onSelect={(selectedKey) => setActive(selectedKey!)}>
              <Nav.Item>
                <Nav.Link to="/" eventKey="default" as={Link}>Home</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link to="about" eventKey="about" as={Link}>About Us</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link to="awards" eventKey="awards" as={Link}>Our Awards</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link to="team" eventKey="team" as={Link}>Our Team</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link to="services" eventKey="services" as={Link}>Our Services</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link to="practice" eventKey="facilities" as={Link}>Our Facilities</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link to="contact" eventKey="contact" as={Link}>Contact Us</Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      )}
      {service.status === 'error' && (<div>Error, the backend moved to the dark side.</div>)}
    </Navbar>
  );
}

export default WeeleyNavBar;
