import React, { useCallback, useState } from 'react';
import Header from '../header/Header';
import Masonry from '../masonry';

const images = [
  "/img/facilities/1.jpeg",
  "/img/facilities/2.jpeg",
  "/img/facilities/3.jpeg",
  "/img/facilities/4.jpeg",
  "/img/facilities/5.jpeg",
  "/img/facilities/6.jpeg",
  "/img/facilities/7.jpeg",
  "/img/facilities/8.jpeg",
  "/img/facilities/9.jpeg",
  "/img/facilities/10.jpeg",
  "/img/facilities/11.jpeg",
  "/img/facilities/12.jpeg",
  "/img/facilities/13.jpeg",
  "/img/facilities/14.jpeg",
  "/img/facilities/15.jpeg",
  "/img/facilities/16.jpeg",
]

interface Props {
  value?: string;
  children?: React.ReactNode;
}

const ImageElement: React.FC<Props> = React.memo(props => {
  return(
    <div className="card shadow-sm">
      <img src={props.value} className="img-fluid" />
    </div>
  )
})

function Practice() {
  const settingColumns = useCallback(() => {
    if(window.innerWidth >= 1400) return 4
    if(window.innerWidth >= 800) return 3
    if(window.innerWidth >= 500) return 2
    return 1
  }, [])

  const [column, setColumn] = useState(() => settingColumns())

  return (
    <div className="container rounded-3 border shadow-lg">
      <Header />
      <div className="row">
        <div className="col-lg-6 col-md-8 mx-auto">
          <h3>Our Facilities</h3>
          <p className="lead text-muted">
We may be small but we have all the facilities you'd expect to find inside a first class local veterinary practice, including anaesthesia and a sterile surgical theatre, x-ray suite, ultrasonography, in-house blood testing, urine testing, separate cat and dog wards, isolation facilities, a dedicated dentistry area and much more.<br />
<br />
We offer veterinary acupuncture to both our patients and referrals from other local practices in our specially designed, comfortable consulting room.<br />
<br />
We understand how important it is to support you when saying goodbye to your pet and have a unique quiet room for these visits. With a specially designed corridor linking you to our car park, you will not need to walk back into the waiting room.<br />
<br />
We have complimentary parking with bays at the front of our premises as well as a car park at the rear.<br />
          </p>
        </div>
      </div>
      <div className="row">
        <Masonry
          dataArray={images}
          columnCount={column}
          ChildsElement={ImageElement}
        />
      </div>
    </div>
  );
}

export default Practice;
