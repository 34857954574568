import React from 'react';
import homeVisits from '../img/home-visits.jpg'

function Visits() {
  return (
    <div className="container">
      <div className="row">
        <h3 id="visits">Home Visits</h3>
      </div>
      <div className="row">
        <img src={homeVisits} alt="Home Visits" />
      </div>
      <div className="row p-0 pb-3">
        <p>
As impressive as our practice is, we understand there are occasions you’d prefer us to come to you. We are usually able to offer home visits Monday to Friday and encourage early booking where possible.<br />
<br />
Due to home visits requiring more time than consultations at our practice, we charge a visit fee in addition to our usual fees. Please ask a member of our reception team for more details.<br />
<br />
        </p>
      </div>
    </div>
  );
}

export default Visits;
