import React from 'react';
import { useParams } from 'react-router-dom';
import TeamMemberService, { TeamCategoryService } from '../api-services/TeamService';
import TeamMemberComponent from './TeamMemberComponent';

function TeamListComponent() {
  const { category = "everybody" } = useParams<{ category: string }>();
  const teamCategoryService = TeamCategoryService();
  const service = TeamMemberService();

  return (
    <div className="container p-0">
      {service.status === 'loading' && <div>Loading...</div>}
      {service.status === 'loaded' && teamCategoryService.status === 'loaded' && (
        <div>
            <div className="row">
                {teamCategoryService.payload.filter(teamCategory => teamCategory.code === category).map(teamCategory => 
                    <h3 id={teamCategory.code} key={teamCategory.code}>{teamCategory.description}</h3>
                )}
            </div>
            <div className="row p-0">
            {teamCategoryService.payload.filter(teamCategory => teamCategory.code === category).map(teamCategory =>
                service.payload.filter(member => member.category === teamCategory.code || teamCategory.code === "everybody").map(member =>
                    <TeamMemberComponent {...member} key={member.name}/>
                )
            )}
            </div>
        </div>
      )}
      {service.status === 'error' && (<div>Error, the backend moved to the dark side.</div>)}
    </div>
  );
}

export default TeamListComponent;
