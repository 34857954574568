import React, { useEffect, useRef } from 'react';
import ReviewsService from '../api-services/ReviewsService'

function Reviews() {
  const service = ReviewsService();

  const reviewRef = useRef<HTMLDivElement>(null);
  var url = "https://vethelpdirect.com/dashboard/api/practice/7023/review-widget";

  useEffect(() => {
    const el = reviewRef.current;
    const script = document.createElement("script");

    script.setAttribute("src", url);
    script.defer=true;
    el?.appendChild(script);

    return () => {
      el?.removeChild(script);
    };
  }, [url]);

  return (
    <div>
      <div className="row">
        <h3 id="concept">Reviews</h3>
        <p className="lead">Here is what our clients say about us.</p>
      </div>
      {service.status === 'loading' && <div>Loading...</div>}
      {service.status === 'loaded' && (
        service.payload.map((review) =>
          <div key={review.key} className="card flex-md-row mb-4 box-shadow h-md-250">
            <div className="card-body d-flex flex-column align-items-start">
              <p className="card-text mb-auto">"{review.body}"</p>
              <p><span className="mb-1 text-muted">{review.author}</span></p>
            </div>
          </div>
        )
      )}
      {service.status === 'error' && (<div>Error, the backend moved to the dark side.</div>)}
      <div ref={reviewRef}>
        <div className="vhdReviewWidget-7023">
        </div>
      </div>
    </div>
  );
}

export default Reviews;
