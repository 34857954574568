import React from 'react';
import { NavLink } from 'react-router-dom';
import vip2 from '../img/vip2.jpg';

function Vip() {
  return (
    <div className="container">
      <div className="row">
        <h3 id="vip">VIP (Very Important Pet) Club</h3>
      </div>
      <div className="row p-0 pb-3 pt-3">
        <p>
We warmly invite you to join our VIP (Very Important Pet) Club.<br />
<br />
Your pets are very important to us so let’s make it official. Join today and receive unlimited savings.<br />
<br />
We are proud to offer phenomenal savings which apply not only to preventative health care but so much more, including medications.<br />
<br />
For a simple monthly direct debit, members of the VIP Club receive the following:<br />
<br />
<ol className="list-group list-group-numbered">
  <li className="list-group-item">Annual core booster vaccination (50% off primary vaccination course)</li>
  <li className="list-group-item">10% off Kennel Cough vaccination</li>
  <li className="list-group-item">Year round flea and worming treatment</li>
  <li className="list-group-item">Two free claws clips</li>
  <li className="list-group-item">£10 offer price for microchipping</li>
  <li className="list-group-item">10% off neutering</li>
  <li className="list-group-item">10% off dentistry</li>
  <li className="list-group-item">10% off medications - yes really!</li>
  <li className="list-group-item">10% off waiting room products</li>
  <li className="list-group-item">We love our patients and can't resist spoiling them which is why we also celebrate their birthday month with a small gift. Did you rescue your furry family member and aren't sure when their birthday is? Let's celebrate the month you adopted them instead!</li>
</ol>
<br />
One off registration fee £12.99<br />
<br />
<ul className="list-group">
  <li className="list-group-item list-group-item-info">Monthly cost</li>
  <li className="list-group-item">Cats £14.99</li>
  <li className="list-group-item">Small Dog (&lt;15kg) £15.99</li>
  <li className="list-group-item">Dog (15kg and above) £19.99</li>
</ul>
<br />
Please pay us a visit to sign up!<br />
<br />
Not yet registered? Please register with us before signing up to our VIP Club using the following link:<br />
<ul className="list-group">
  <li className="list-group-item list-group-item-info"><NavLink to="/services/register" className="link-primary">Register</NavLink></li>
</ul>

        </p>
      </div>
      <div className="row">
        <img src={vip2} alt="Very Important Pet" />
      </div>
    </div>
  );
}

export default Vip;
