import React from 'react';
import ConfigurationService from '../api-services/ConfigurationService';
import Header from '../header/Header';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

function Contact() {
  const service = ConfigurationService();

  const coordinates = {
    latitude: 51.8589409,
    longitude: 1.1136441
  }
  
  return (
    <div className="container rounded-3 border shadow-lg">
      {service.status === 'loading' && <div>Loading...</div>}
      {service.status === 'loaded' && (
        <div>
          <Header />
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 pb-2">
                  <h3>How to Find Us</h3>
                  <div className="span4">
                    <dl>
                      <dt>Address</dt>
                      <dd>
                        <address>
                          26 Colchester Road<br />
                          Weeley<br />
                          Essex<br />
                          CO16 9JT<br />
                          <abbr title="Phone">P:</abbr>&nbsp;<a href={"tel:" + service.payload.phone}>{service.payload.phone}</a>
                        </address>
                      </dd>
                      <dt>Opening Hours</dt>
                      <dt>
                      </dt>
                      <dd>Monday-Friday 9am-6:30pm</dd>
                      <dd>Saturday 9am-12:30pm</dd>
                    </dl>
                    <a href={"https://www.google.com/maps/place/26+Colchester+Rd,+Weeley,+Clacton-on-Sea+CO16+9JT/@" + coordinates.latitude + "," + coordinates.longitude} target="_blank" rel="noreferrer" className="btn btn-primary me-3">Show on Map</a>
                    <a href={"mailto:" + service.payload.email} className="btn btn-success">Email Us</a>
                  </div>
                </div>
                <div className="col">
                  <MapContainer center={[coordinates.latitude, coordinates.longitude]} zoom={17} scrollWheelZoom={false}>
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={[coordinates.latitude, coordinates.longitude]}>
                      <Popup>
                        Weeley Vet Centre, 26 Colchester Road, Weeley, Essex. CO16 9JT.
                      </Popup>
                    </Marker>
                  </MapContainer>
                </div>
              </div>
              <div className="row">
                <strong>If you require veterinary advice or treatment outside of our opening hours, this is provided by VetsNow</strong><br />
                <br />
                <dl>
                  <dt>VetsNow</dt>
                  <dd>
                    <address>
                      70 Brinkley Lane<br />
                      Highwoods<br />
                      Colchester<br />
                      CO4 9XE<br />
                      <abbr title="Phone">P:</abbr>&nbsp;<a href={"tel:" + service.payload.vetsNowPhone}>{service.payload.vetsNowPhone}</a>
                    </address>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      )}
      {service.status === 'error' && (<div>Error, the backend moved to the dark side.</div>)}
    </div>
  );
}

export default Contact;