import React from 'react';
import logo from '../img/logo.png';

function DebtRecovery() {
  return (
    <div className="container">
      <div className="row p-0 rounded-3 border shadow-lg">
        <div className="col-md-9 col-xl-10 min-vh-100 rounded-end">
          <div className="row rounded-end">
            <div className="text-centre">
              <img src={logo} alt="Weeley Vet Centre" className="align-centre img-fluid" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 p-5">
              <div className="row">
                <h3>Debt Recovery Charges</h3>
                <br />
                <p>
In the event of non-payment of any invoice in its entirety within the required period, further administrative and debt recovery charges may be applied to your account and referral to Debt Recovery can take place forthwith. 
                </p>
                <p>
Please note that upon referral to a debt recovery agency of our choice, you will incur additional fees potentially up to a maximum sum of £5,000. You agree to reimburse us of all debt recovery charges we incur in the pursuit of recovering the debt due to the additional correspondence, phone calls, reports, administration costs and other debt recovery actions.
You contractually agree to pay these additional debt recovery sums and interest as a result of your non-payment under the agreed payment terms. 
                </p>
                <p>
If the account remains in arrears 30 days after referral to the debt recovery agency, we reserve the right to commence legal proceedings. You agree that you will be liable for any additional debt recovery costs thereafter as well as legal costs. You agree to be liable for our legal costs on an indemnity basis in addition to the principal sum and interest.
                </p>
                <p>
We reserve the right to not undertake any further commercial dealings while the debt is outstanding and we can suspend the provision of any further goods and/or services until the outstanding sum is paid in full. Please note county court judgments can impact your credit rating.
                </p>
                <p>
Interest will be applied at 8% above the Bank of England base rate.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DebtRecovery;
