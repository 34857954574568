import React from 'react';
import sayingGoodbye from '../img/saying-goodbye.jpg'

function Goodbye() {
  return (
    <div className="container">
      <div className="row">
        <h3 id="goodbye">Saying Goodbye</h3>
      </div>
      <div className="row">
        <img src={sayingGoodbye} alt="Home Visits" />
      </div>
      <div className="row p-0 pb-3">
        <p>
Every member of our team understands the importance of caring for both you and your pet if the time for euthanasia has arrived. We are proud of our ability to offer pet owners the chance to lay their beloved animals to rest with dignity and privacy in our unique quiet room.<br />
<br />
Saying goodbye to a pet is the most difficult moment, but by providing end of life services in a dedicated room, we hope to make these visits as caring as possible.<br />
<br />
        </p>
      </div>
    </div>
  );
}

export default Goodbye;
