import React, { useEffect, useRef } from 'react';
import hero from '../img/hero.png';
import { NavLink } from "react-router-dom";
import RegisterService from '../api-services/RegisterService';

function Home() {
  const registerService = RegisterService();
  
  const reviewRef = useRef<HTMLDivElement>(null);
  var url = "https://vethelpdirect.com/dashboard/api/practice/7023/review-widget?compact=true";

  useEffect(() => {
    const el = reviewRef.current;
    const script = document.createElement("script");

    script.setAttribute("src", url);
    script.defer=true;
    el?.appendChild(script);

    return () => {
      el?.removeChild(script);
    };
  }, [url]);
  
  return (
    <div className="container">
      <div className="row p-4 rounded-3 border shadow-lg text-center">
        <h1 className="display-4 fw-bold lh-1 pb-5">Weeley Veterinary Centre</h1><br /><br />
          <div className="container">
              <div className="row">
                <div className="col-lg-2">
                  <img src="/img/awards/trophy-icon.jpeg" alt="2023 award" className="card-img img-fluid"/>
                </div>
                <div className="col-lg-8 text-center">
                  <h2 className="display-7"><span className="bg-success text-light rounded px-2">Winner of Best Vet UK 2023</span></h2><br />
                  <h2 className="display-7"><span className="bg-info text-light rounded px-2">and!</span></h2><br />
                  <h2 className="display-7"><span className="bg-success text-light rounded px-2">Best Small Vet UK 2024</span></h2><br />
                </div>
                <div className="col-lg-2">
                  <img src="/img/awards/trophy-icon.jpeg" alt="2024 award" className="card-img img-fluid"/>
                </div>
              </div>
            </div>
        <div className="col-lg-7 pt-5">
          <div className="card p-0 text-centre">
            <img src="/img/awards/Team2.jpeg" alt="homepage" className="card-img img-fluid"/>
          </div>
          <p className="lead">
            Exceptional veterinary care from local people you can trust.<br />
            <br />
            We are the first veterinary practice to win Best Vet UK in their opening year. We are proudly independent and look forward to welcoming you.<br />
            <br />
          </p>
          <div className="d-grid gap-2 d-md-flex justify-content-md-center mb-4 mb-lg-3">
            {registerService.status === 'loading' && <div>Loading...</div>}
            {registerService.status === 'loaded' && (
              <div>
                <NavLink to="awards" className="btn btn-success btn-lg px-4 me-md-2 fw-bold">Our Awards</NavLink>
                <NavLink to="services/register" className="btn btn-primary btn-lg px-4 me-md-2 fw-bold">{registerService.payload.title}</NavLink>
              </div>
            )}
            {registerService.status === 'error' && (<div>Error, the backend moved to the dark side.</div>)}
          </div>
        </div>
        <div className="col-lg-5 pt-5 overflow-hidden text-center">
          <img className="align-centre img-fluid" src={hero} alt="" width="300" />
          <div ref={reviewRef}>
            <div className="vhdReviewWidget-7023">
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
