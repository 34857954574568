import { useEffect, useState } from 'react';
import { Service } from '../api-objects/Service';
import { TeamCategory } from '../api-objects/TeamCategory';
import { TeamMember } from '../api-objects/TeamMember';

const TeamMemberService = () => {
  const [result, setResult] = useState<Service<TeamMember[]>>({
    status: 'loading'
  });

  useEffect(() => {
    fetch('/api/team.json')
      .then(response => response.json())
      .then(response => setResult({ status: 'loaded', payload: response }))
      .catch(error => setResult({ status: 'error', error }));
  }, []);

  return result;
};

const TeamCategoryService = () => {
  const [result, setResult] = useState<Service<TeamCategory[]>>({
    status: 'loading'
  });

  useEffect(() => {
    fetch('/api/teamcategories.json')
      .then(response => response.json())
      .then(response => setResult({ status: 'loaded', payload: response }))
      .catch(error => setResult({ status: 'error', error }));
  }, []);

  return result;
};

export default TeamMemberService;
export { TeamMemberService, TeamCategoryService };