import React from 'react';
import logo from '../img/logo.png';

function Header() {
  return (
    <div className="row p-0">
        <div className="text-center">
        <img src={logo} alt="Weeley Vet Centre" className="align-centre img-fluid" />
        </div>
    </div>
  );
}

export default Header;