import React from 'react';

function Concept() {
  return (
    <div className="row">
      <h3 id="concept">The Concept</h3>
      <p className="lead">
Personal, high quality veterinary care at fair prices.<br />
<br />
We know when your pet is sick, receiving the very highest standard of care from someone you trust is essential.<br />
<br />
We also understand that seeing the same vet every time you visit is important and should be standard service.<br />
<br />
Our small and dedicated team are here to serve you and your furry family members. We pride ourselves on providing excellent care with the same vet in almost every situation.<br />
<br />
We want your pet to feel safe and relaxed during their visit to our clinic which is why our waiting room is specially designed with them in mind and cats and dogs have their own unique waiting areas. The cat area resembles a comfortable living room whilst the dog area feels like a trip to the park. Please pay us a visit and experience it for yourselves. We look forward to welcoming you.<br />
      </p>
    </div>
  );
}

export default Concept;
