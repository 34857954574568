import React, { useState } from 'react';
import { useEffect } from 'react';
import { useForm, useFieldArray, SubmitHandler } from "react-hook-form";
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Privacy from '../policy/Privacy';
import RegisterService from '../api-services/RegisterService';

type FormObject = {
  registration: Registration;
  services: Services;
  research: Research;
}

type Authentication = {
  username: string;
  password: string;
}

type Registration = {
  salutation: string;
  firstname: string;
  secondname: string;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  postcode: string;
  landlineNumber: string;
  mobileNumber: string;
  additionalMobileNumber: string;
  emailAddress: string;
  pets: Pet[];
};

type Pet = {
  petNo: number;
  name: string;
  species: string;
  breed: string;
  colour: string;
  dob: string;
  microchipNo: string;
  sex: string;
  insured: boolean;
  currentVetPractice: string;
}

type Services = {
  intendsToJoinVIP: boolean;
  needsAppointment: boolean;
}

type Research = {
  heardAboutFrom: string;
  whyWeeley: string;
}

function Register() {
  const service = RegisterService();
  const [message, setMessage] = useState("");
  const [showFailure, setShowFailure] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const { register, control, handleSubmit, reset, watch, formState: { errors } } = useForm<FormObject>();
  const { fields, append, remove } = useFieldArray({ name: 'registration.pets', control });
  
  const [numberOfPets, setNumberOfPets] = useState(0);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [formData, setFormData] = useState<FormObject>();

  const handleReject = () => setShowPrivacyPolicy(false);
  const handleShow = () => setShowPrivacyPolicy(true);

  useEffect(() => {
      if (numberOfPets < 1) {
        setNumberOfPets(1);
      } else {
        // update field array when ticket number changed
        const oldVal = fields.length;
        if (numberOfPets > oldVal) {
            // append tickets to field array
            for (let i = oldVal; i < numberOfPets; i++) {
                append({ name: ''});
            }
        } else {
            // remove tickets from field array
            for (let i = oldVal; i > numberOfPets; i--) {
                remove(i - 1);
            }
        }
      }
  }, [numberOfPets]);

  const onSubmit: SubmitHandler<FormObject> = data => {
    setFormData(data)
    setShowPrivacyPolicy(true)
  }

  const handleAccept = () => {
    const data = formData;
    if (data) {
      (async () => {
        const fetchResult = await fetch('/register.php', {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        });

        setShowPrivacyPolicy(false)
        if (fetchResult.ok) {
          const content = await fetchResult.text();
          setMessage(content);
          setShowFailure(false);
          setShowSuccess(true);
        } else {
          setMessage(fetchResult.status + ": " + fetchResult.statusText);
          setShowSuccess(false);
          setShowFailure(true);
        }

      })();
    }
  };

  return (
    <div className="container">
      {service.status === 'loading' && <div>Loading...</div>}
      {service.status === 'loaded' && (
        <div>
          <Modal show={showPrivacyPolicy} onHide={handleReject} dialogClassName="modal-90w">
            <Modal.Header closeButton>
              <Modal.Title>Privacy Policy</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Privacy />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleAccept}>
                Accept
              </Button>
              <Button variant="danger" onClick={handleReject}>
                Reject
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="row p-3">
            <h3 id="register">{service.payload.title}</h3>
          </div>
          <div className="row p-3 pb-3">
            <p style={{whiteSpace: "pre-wrap"}}>
            {service.payload.body}
            <br /><br />
            Please take a moment to learn about our VIP Club…
            <img src="/img/vip-registration.png" alt="VIP Registration Details" className="img-fluid" />
            </p>
            <h3>Registration</h3>
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Modal show={showFailure} variant="danger">
                    <Modal.Header>
                      <Modal.Title>Error!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {message}
                    </Modal.Body>
                    <Modal.Footer>
                      <Button onClick={() => setShowFailure(false)} variant="outline-danger">
                        Dismiss
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <Modal show={showSuccess} variant="success">
                    <Modal.Header>
                      <Modal.Title>Success</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      Thank you for Registering!
                    </Modal.Body>
                    <Modal.Footer>
                      <Button onClick={() => {setShowSuccess(false); reset()}} variant="outline-success">
                        Dismiss
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <div className="row">
                    <h4>Your Details</h4>
                    <div className="row mb-3 position-relative">
                      <label htmlFor="fullNameInput" className="col-sm-2 col-form-label">Full Name*</label>
                      <div className="col-sm-10">
                        <div className="input-group" id="fullNameInput">
                          <select className="form-select" id="salutationInput"  style={{"width": "10%"}} {...register("registration.salutation", { required: true })}>
                            <option selected>Salutation</option>
                            <option value="Miss">Miss</option>
                            <option value="Mrs">Mrs</option>
                            <option value="Ms">Ms</option>
                            <option value="Mr">Mr</option>
                            <option value="Other/None">Other/None</option>
                          </select>
                          <input type="text" placeholder="Firstname(s)" className="form-control" id="firstNameInput" {...register("registration.firstname", { required: true })} />
                          <input type="text" placeholder="Secondname" className="form-control" id="secondNameInput" {...register("registration.secondname", { required: true })} />
                        </div>
                        {(errors.registration?.salutation || errors.registration?.firstname || errors.registration?.secondname) && <div className="invalid-tooltip" style={{display: "block"}}>This field is required</div>}
                      </div>
                    </div>
                    <div className="row mb-3 position-relative">
                      <label htmlFor="address1Input" className="col-sm-2 col-form-label">Full Address</label>
                      <div className="col-sm-10">
                        <div className="input-group mb-3">
                          <span className="input-group-text" id="basic-addon1" style={{"width": "12%"}}>Line 1*</span>
                          <input type="text" className="form-control" id="address1Input" {...register("registration.address1", { required: true })} />
                        </div>
                        <div className="input-group mb-3">
                          <span className="input-group-text" id="basic-addon1" style={{"width": "12%"}}>Line 2</span>
                          <input type="text" className="form-control" id="address2Input" {...register("registration.address2")} />
                        </div>
                        <div className="input-group mb-3">
                          <span className="input-group-text" id="basic-addon1" style={{"width": "12%"}}>Line 3</span>
                          <input type="text" className="form-control" id="address3Input" {...register("registration.address3")} />
                        </div>
                        <div className="input-group mb-3">
                          <span className="input-group-text" id="basic-addon1" style={{"width": "12%"}}>Line 4</span>
                          <input type="text" className="form-control" id="address4Input" {...register("registration.address4")} />
                        </div>
                        <div className="input-group mb-3">
                          <span className="input-group-text" id="postcodeInput-addon1" style={{"width": "12%"}}>Postcode*</span>
                          <input type="text" className="form-control" id="postcodeInput" {...register("registration.postcode", { required: true })} />
                        </div>
                        {(errors.registration?.address1 || errors.registration?.postcode) && <div className="invalid-tooltip" style={{display: "block"}}>This field is required</div>}
                      </div>
                    </div>
                    <div className="row mb-3 position-relative">
                      <label htmlFor="landlineInput" className="col-sm-2 col-form-label">Landline Number</label>
                      <div className="col-sm-10">
                        <input type="text" className="form-control" id="landlineInput" {...register("registration.landlineNumber")} />
                      </div>
                    </div>
                    <div className="row mb-3 position-relative">
                      <label htmlFor="mobileInput" className="col-sm-2 col-form-label">Mobile Number*</label>
                      <div className="col-sm-10">
                        <input type="text" className="form-control" id="mobileInput" {...register("registration.mobileNumber", { required: true })} />
                        {errors.registration?.mobileNumber && <div className="invalid-tooltip" style={{display: "block"}}>This field is required</div>}
                      </div>
                    </div>
                    <div className="row mb-3 position-relative">
                      <label htmlFor="additionalMobileInput" className="col-sm-2 col-form-label">Additional Mobile Number</label>
                      <div className="col-sm-10">
                        <input type="text" className="form-control" id="additionalMobileInput" {...register("registration.additionalMobileNumber")} />
                      </div>
                    </div>
                    <div className="row mb-3 position-relative">
                      <label htmlFor="emailInput" className="col-sm-2 col-form-label">Email Address*</label>
                      <div className="col-sm-10">
                        <input type="email" className="form-control" id="emailInput" {...register("registration.emailAddress", { required: true })} />
                        {errors.registration?.emailAddress && <div className="invalid-tooltip" style={{display: "block"}}>This field is required</div>}
                      </div>
                    </div>
                    <h4>Please help us to serve you better</h4>
                    <div className="row mb-3">
                      <div className="col-sm-10 offset-sm-2">
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" id="intendsToJoinVIP" {...register("services.intendsToJoinVIP")}/>
                          <label className="form-check-label" htmlFor="intendsToJoinVIP">
                            Do you intend to sign up to our VIP Club? 
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-sm-10 offset-sm-2">
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" id="needsAppointment" {...register("services.needsAppointment")}/>
                          <label className="form-check-label" htmlFor="needsAppointment">
                            Do you wish to make an appointment at Weeley Veterinary Centre as soon as possible? 
                          </label>
                        </div>
                      </div>
                    </div>
                    <h4>For research purposes, please can you answer the following questions?</h4>
                    <div className="row mb-3 position-relative">
                      <label htmlFor="heardAboutFromInput"  className="col-sm-2 col-form-label">How did you hear about Weeley Veterinary Centre?</label>
                      <div className="col-sm-10">
                        <input type="text" className="form-control" id="heardAboutFromInput" {...register("research.heardAboutFrom")} />
                      </div>
                    </div>
                    <div className="row mb-3 position-relative">
                      <label htmlFor="whyWeeleyInput" className="col-sm-2 col-form-label">Why would you like the team at Weeley Veterinary Centre to look after your pet(s)?</label>
                      <div className="col-sm-10">
                        <textarea className="form-control" id="whyWeeleyInput" rows={3} {...register("research.whyWeeley")}></textarea>
                      </div>
                    </div>
                    <h4>Details of your pet(s)</h4>
                    {fields.map((item, i) => (
                      <div key={i} className="pb-3">
                        <div className="card">
                          <div className="card-body">
                            <input type="text" className="form-control d-none" id={`registration.pets[${i}]petNo`} value={i+1} {...register(`registration.pets.${i}.petNo`, { required: true })} />
                            <div className="row mb-3 position-relative">
                              <label htmlFor={`registration.pets[${i}]name`}  className="col-sm-2 col-form-label">Pets Name*</label>
                              <div className="col-sm-10">
                                <input type="text" className="form-control" id={`registration.pets[${i}]name`} {...register(`registration.pets.${i}.name`, { required: true })} />
                                {errors.registration?.pets?.[i]?.name && <div className="invalid-tooltip" style={{display: "block"}}>This field is required</div>}
                              </div>
                            </div>
                            <div className="row mb-3 position-relative">
                              <label htmlFor={`registration.pets[${i}]species`}  className="col-sm-2 col-form-label">Species*</label>
                              <div className="col-sm-10">
                                <input type="text" className="form-control" id={`registration.pets[${i}]species`} placeholder="e.g. dog, cat, rabbit, hamster"  {...register(`registration.pets.${i}.species`, { required: true })} />
                                {errors.registration?.pets?.[i]?.species && <div className="invalid-tooltip" style={{display: "block"}}>This field is required</div>}
                              </div>
                            </div>
                            <div className="row mb-3 position-relative">
                              <label htmlFor={`registration.pets[${i}]breed`}  className="col-sm-2 col-form-label">Breed*</label>
                              <div className="col-sm-10">
                                <input type="text" className="form-control" id={`registration.pets[${i}]breed`} placeholder="e.g. labrador, domestic short hair" {...register(`registration.pets.${i}.breed`, { required: true })} />
                                {errors.registration?.pets?.[i]?.breed && <div className="invalid-tooltip" style={{display: "block"}}>This field is required</div>}
                              </div>
                            </div>
                            <div className="row mb-3 position-relative">
                              <label htmlFor={`registration.pets[${i}]colour`}  className="col-sm-2 col-form-label">Colour*</label>
                              <div className="col-sm-10">
                                <input type="text" className="form-control" id={`registration.pets[${i}]colour`} placeholder="e.g. black, tabby" {...register(`registration.pets.${i}.colour`, { required: true })} />
                                {errors.registration?.pets?.[i]?.colour && <div className="invalid-tooltip" style={{display: "block"}}>This field is required</div>}
                              </div>
                            </div>
                            <div className="row mb-3 position-relative">
                              <label htmlFor={`registration.pets[${i}]dob`}  className="col-sm-2 col-form-label">Date of birth* </label>
                              <div className="col-sm-10">
                                <input type="text" className="form-control" id={`registration.pets[${i}]dob`} placeholder="DD/MM/YYYY (please estimate if unknown)" {...register(`registration.pets.${i}.dob`, { required: true })} />
                                {errors.registration?.pets?.[i]?.dob && <div className="invalid-tooltip" style={{display: "block"}}>This field is required</div>}
                              </div>
                            </div>
                            <div className="row mb-3 position-relative">
                              <label htmlFor={`registration.pets[${i}]microchipNo`}  className="col-sm-2 col-form-label">Microchip number</label>
                              <div className="col-sm-10">
                                <input type="text" className="form-control" id={`registration.pets[${i}]microchipNo`}  {...register(`registration.pets.${i}.microchipNo`)} />
                              </div>
                            </div>
                            <div className="row mb-3 position-relative">
                              <label htmlFor={`registration.pets[${i}]sex`}  className="col-sm-2 col-form-label">Sex*</label>
                              <div className="col-sm-10">
                                <select className="form-select" id={`registration.pets[${i}]sex`} {...register(`registration.pets.${i}.sex`, { required: true })}>
                                  <option value="">Choose...</option>
                                  <option value="male entire">male entire</option>
                                  <option value="male neutered">male neutered</option>
                                  <option value="female entire">female entire</option>
                                  <option value="female neutered">female neutered</option>
                                  <option value="other/unknown">other/unknown</option>
                                </select>
                                {errors.registration?.pets?.[i]?.sex && <div className="invalid-tooltip" style={{display: "block"}}>This field is required</div>}
                              </div>
                            </div>
                            <div className="row mb-3 position-relative">
                              <label htmlFor={`registration.pets[${i}]insurance`}  className="col-sm-2 col-form-label">Are they insured?</label>
                              <div className="col-sm-10">
                                <input className="form-check-input" type="checkbox" id={`registration.pets[${i}]insured`} {...register(`registration.pets.${i}.insured`)}/>
                              </div>
                            </div>
                            <div className="row mb-3 position-relative">
                              <label htmlFor={`registration.pets[${i}]insurance`}  className="col-sm-2 col-form-label">If yes, please state the insurance company</label>
                              <div className="col-sm-10">
                                <input type="text" placeholder="Insurance Company" className="form-control" id={`registration.pets[${i}]insuranceCompany`} />
                              </div>
                            </div>
                            <div className="row mb-3 position-relative">
                              <label htmlFor={`registration.pets[${i}]currentVetPractice`}  className="col-sm-2 col-form-label">Name of current veterinary practice**</label>
                              <div className="col-sm-10">
                                <input type="text" className="form-control" id={`registration.pets[${i}]currentVetPractice`}  {...register(`registration.pets.${i}.currentVetPractice`)} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="input-group mb-3">
                    <button onClick={() => setNumberOfPets(numberOfPets + 1)} type="button" className="btn btn-primary">Add Additional Pet</button>
                    <button onClick={() => setNumberOfPets(numberOfPets - 1)} type="button" className="btn btn-secondary">Remove Pet</button>
                  </div>
                  <br />
                  <p>*&nbsp;&nbsp;Required Field.</p>
                  <p>**&nbsp;Please note, by providing the name of your current veterinary practice on this registration form, you are giving consent for us to contact them to request your pet’s clinical notes, allowing us to deliver exceptional and seamless care.</p>
                  <div className="input-group mb-3">
                    <button type="submit" className="btn btn-primary">Submit</button>
                    <button type="button" onClick={() => reset()} className="btn btn-secondary">Reset</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      {service.status === 'error' && (<div>Error, the backend moved to the dark side.</div>)}
    </div>
  );
}

export default Register;
